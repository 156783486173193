import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../hoc/debouncer';

import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import Button from '../common/Button/Button';
import { Icon } from '../common/Icon/Icon';
import Pagination from '../common/Pagination/Pagination';

import {
  LoaderContainer,
  ProjectsContainer,
  HeaderContainer,
  PaginationContainer,
  StyledHeader,
} from './Projects.styles';
import ProjectCards from './ProjectsCards/ProjectsCards';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import ProjectFilter from './ProjectFilters/ProjectFilter';
import NoProjects from './NoProjects/NoProjects';

import { getCompanyTeams } from '../../services/company';
import { getCatProject } from '../../services/cat';
import { getProjects as getProjectsAxios } from '../../services/project';
import { setProjects } from '../../store/projectsSlice';
import { STATUS } from '../common/StatusLabel/StatusLabel.constants';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'utils/storage.utils';

const INITIAL_PROJECT_PARAMS = {
  per_page: 15,
  page: 1,
  search: '',
  status_id: Object.keys(STATUS).filter((key) => key !== '20'),
  team_id: [],
  sort_by: 'id',
  sort_order: 'DESC',
  user_id: null,
};

const Projects = () => {
  const [showRedirect, setShowRedirect] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialLoad, setInitialLoad] = useLocalStorage('projects-view-initial-params', true);
  const [searchParams, setSearchParams] = useLocalStorage('projects-view-params', INITIAL_PROJECT_PARAMS);
  const [showFilterControls, setShowFilterControls] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [teamOptions, setTeamOptions] = useState([]);
  const [viewType, setViewType] = useLocalStorage('view-type', 'GRID');

  const projects = useSelector((state) => state.projectsStore.projects);
  const user = useSelector((state) => state.userStore.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { debounce } = useDebounce();

  const updateSearchParams = (newParams) => {
    // this resets page number to 1 since we are getting new results
    const updatedParams = { ...searchParams, ...newParams };
    setSearchParams(updatedParams);
    setInitialLoad(false);
  };

  const updatePage = (e, { activePage }) => {
    const updatedParams = { ...searchParams, page: activePage };
    setSearchParams(updatedParams);
    setInitialLoad(false);
  };

  const resetSearchParams = () => {
    const newSearchParams = {
      ...INITIAL_PROJECT_PARAMS,
      team_id: teamOptions.map((team) => team.id),
    };

    updateSearchParams(newSearchParams);
    setInitialLoad(true);
  };

  const redirectToCreate = () => {
    history.push('/projects/create');
  };

  const handleChangeView = (viewtype) => {
    setViewType(viewtype);
  };

  useEffect(() => {
    const getProjectProgress = async ({ cat_id, cat_pass, cat_setting, status_id }) => {
      try {
        const catStatuses = [3, 4, 10, 11];
        let progress = 0;

        if (status_id === 6 || status_id === 11 || status_id === 20) {
          progress = 100;
        } else if (status_id === 5) {
          progress = 90;
        } else if (catStatuses.includes(status_id)) {
          if (cat_id && cat_pass && cat_setting) {
            const getCatProjectResponse = await getCatProject({
              baseUrl: cat_setting.link,
              catId: cat_id,
              catPass: cat_pass,
            });

            if (getCatProjectResponse.hasOwnProperty('project')) {
              const arr = getCatProjectResponse.project.jobs.map((job) => job.stats.PROGRESS_PERC);
              const reducedProgress = (arr.reduce((acc, next) => acc + next, 0) / arr.length) * 0.9;

              if (reducedProgress > 90) progress = 90;
            } else {
              progress = 0;
            }
          } else {
            progress = 0;
          }
        }
        return progress;
      } catch (e) {
        return null;
      }
    };

    const getProjects = async (searchParams) => {
      debounce(
        'getProjects',
        async () => {
          try {
            setIsLoading(true);
            const { data, last_page } = await getProjectsAxios({ ...searchParams });
            if (searchParams.page > last_page) {
              setSearchParams({ ...searchParams, page: last_page });
            }
            setTotalPages(last_page);

            const projects = await Promise.all(
              data.map(async (project) => {
                try {
                  const progress = await getProjectProgress(project);
                  return {
                    ...project,
                    progress: progress,
                  };
                } catch (e) {
                  // in case CAT request fails, return progress as null
                  return {
                    ...project,
                    progress: null,
                  };
                }
              }),
            );

            const newProjects = projects.filter((project) => project.progress !== null);

            dispatch(setProjects({ projects: newProjects }));
            const showRedirect = initialLoad && newProjects.length === 0;
            setShowRedirect(showRedirect);
          } catch (e) {
            throw e;
          } finally {
            setIsLoading(false);
          }
        },
        500,
      );
    };

    const fetchProjects = async () => {
      try {
        await getProjects(searchParams);
      } catch (e) {
        setError(e);
      }
    };
    fetchProjects();
  }, [initialLoad, searchParams, debounce, dispatch, user, setSearchParams]);

  useEffect(() => {
    const getTeamOptions = async (searchParams) => {
      const { company_owner_id, role, teams } = user;
      try {
        if (role === 40) {
          // if company manager get all teams for that company
          const teamsResponse = await getCompanyTeams(company_owner_id);
          // select all user teams by default for search filters
          const newTeamIds = teamsResponse.map((team) => team.id);
          const newSearchParams = { ...searchParams, team_id: newTeamIds };
          setSearchParams(newSearchParams);
          setTeamOptions(teamsResponse);
        } else {
          // select all user teams by default for search filters
          const newTeamIds = teams.map((team) => team.id);
          const newSearchParams = { ...searchParams, team_id: newTeamIds };
          setSearchParams(newSearchParams);
          setTeamOptions(teams);
        }
      } catch (e) {
        setTeamOptions([]);
      }
    };
    const fetchTeams = async () => {
      try {
        await getTeamOptions(searchParams);
      } catch (e) {
        setError(e);
      }
    };
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadProjects = () => {
    setSearchParams({ ...searchParams });
  };

  // Show header only if not loading and user has projects
  const showHeader = !isLoading && !showRedirect;

  return (
    <ProjectsContainer>
      {showHeader && (
        <HeaderContainer>
          <StyledHeader className="hide-on-mobile">{t('common:projects.myProjects')}</StyledHeader>
          <Button
            data-cy="start-new-project-button"
            onClick={redirectToCreate}
            labelPosition="left"
            actiontype="primary"
          >
            <Icon name="plus-square" />
            {t('common:projects.startANewProject')}
          </Button>
          <ProjectFilter
            hideSidebar={() => setShowFilterControls(false)}
            isVisible={showFilterControls}
            nameQuery={searchParams.search}
            selectedStatuses={searchParams.status_id}
            selectedTeams={searchParams.team_id}
            selectedUserID={searchParams.user_id}
            sortParam={searchParams.sort_by}
            sortOrder={searchParams.sort_order}
            teamOptions={teamOptions}
            updateSearchParams={updateSearchParams}
            resetSearchParams={resetSearchParams}
            trigger={(props) => (
              <Button {...props} icon="filter" color="grey" basic style={{ height: '100%', width: '40px' }} />
            )}
            viewType={viewType}
            setViewType={handleChangeView}
            userID={user.id}
            // Only show if user id is set
            displayShowFilter={!!user.id}
          />
        </HeaderContainer>
      )}
      {isLoading ? (
        <LoaderContainer>
          <Loader>{`${t('common:projects.projectInfo.loadingProjects')}...`}</Loader>
        </LoaderContainer>
      ) : error ? (
        <Message text={error} type="error" />
      ) : showRedirect ? (
        <NoProjects />
      ) : (
        <>
          <div style={{ marginBottom: '3em' }}>
            {viewType === 'GRID' ? (
              <ProjectCards projects={projects} reloadProjects={reloadProjects} />
            ) : (
              <ProjectsTable projects={projects} reloadProjects={reloadProjects} />
            )}
          </div>
          <PaginationContainer>
            {totalPages > 1 && (
              <Pagination activePage={searchParams.page} onPageChange={updatePage} totalPages={totalPages} />
            )}
          </PaginationContainer>
        </>
      )}
    </ProjectsContainer>
  );
};

export default Projects;
