import axios from 'axios';
import httpClient from './httpclient';

import { hasAdminPermissions } from '../utils/user.utils';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';

export const getProjects = async ({
  per_page,
  page,
  search,
  status_id,
  team_id,
  sort_by,
  sort_order,
  user_id,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/projects`, {
      params: {
        per_page: per_page,
        page: page,
        search,
        status_id,
        sort_by,
        sort_order,
        team_id,
        user_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postArchiveProject = async (projectId) => {
  try {
    const { data } = await httpClient.post(`/auth/project/archivemyproject`, {
      project_id: projectId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postFailedProject = async (projectId) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${projectId}/fail`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTransferProject = async (projectId, userId) => {
  try {
    const { data } = await httpClient.post(`/auth/sales/transfermyproject`, {
      project_id: projectId,
      user_id: userId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateProject = async (project, user, diy = false, diyFinish = false) => {
  try {
    const { id, jobs, total_word, total_payable, repeated_words, regional_disc, special_disc, category_id } =
      project;

    let body = {
      id,
      jobs,
      total_word,
      total_payable,
      repeated_words,
      category_id,
    };

    if (hasAdminPermissions(user.role) || user.role === 61) {
      body = {
        id,
        jobs,
        total_word,
        total_payable,
        repeated_words,
        regional_disc,
        special_disc,
      };
    }

    if (diy) {
      body = { id };
    }

    if (diyFinish) {
      body = { id, diyFinish };
    }

    const { data } = await httpClient.post(`/auth/project/updateproject`, body);

    return data;
  } catch (e) {
    throw e;
  }
};

export const getProject = async (id) => {
  try {
    const { data } = await httpClient.get(`/auth/project/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUnarchiveProject = async (id) => {
  try {
    const response = await httpClient.post(`/auth/project/${id}/un-archive`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await httpClient.delete(`/auth/project/${id}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postUpdateProjectById = async ({
  category_id,
  comment,
  diy,
  id,
  billing_detail_id,
  name,
  total_words,
  status_id,
}) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${id}/update`, {
      category_id,
      comment,
      diy,
      billing_detail_id,
      name,
      total_words,
      status_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const sendChatNotification = async ({ errorMessage }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/error`, {
      error_message: errorMessage,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTempProject = async (
  { category, files, sourceLanguage, targetLanguage, uniqueId },
  onUploadCallback,
) => {
  try {
    const formData = new FormData();

    formData.append('source_language', sourceLanguage);
    formData.append('target_language', targetLanguage);
    formData.append('category', category);
    // TODO check BE why this is needed at all
    formData.append('possible_id', uniqueId);

    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    const { data } = await httpClient.post(`/auth/tempprojects`, formData, {
      onUploadProgress: (e) => {
        if (onUploadCallback) {
          const completionPercentage = Math.round((e.loaded * 100) / e.total);
          onUploadCallback(completionPercentage);
        }
      },
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export const postCreateProject = async ({
  catId,
  catPass,
  files,
  projectName,
  sourceLanguage,
  targetLanguages,
  taiaOCR,
  team_id,
  categoryId,
  private_tm_key,
  new_keys,
}) => {
  try {
    const formData = new FormData();

    formData.append('name', projectName);
    formData.append('cat_id', catId);
    formData.append('cat_pass', catPass);
    formData.append('source_language', sourceLanguage);
    formData.append('target_language', targetLanguages);

    if (team_id) {
      formData.append('team_id', team_id);
    }

    formData.append('category_id', categoryId);
    formData.append('private_tm_key', private_tm_key);
    if (new_keys.length > 0) {
      for (let key of new_keys) {
        formData.append('new_keys[]', key);
      }
    }

    if (taiaOCR === 1) {
      formData.append('taia_ocr', 1);
    }

    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    const { data } = await httpClient.post(`/auth/createproject`, formData);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTaiaOcrFiles = async (
  { files, sourceLanguage, targetLanguages, whichCat },
  onUploadCallback,
) => {
  try {
    const formData = new FormData();
    formData.append('cat_tool', whichCat);
    formData.append('source_lang', sourceLanguage);
    formData.append('target_lang', targetLanguages);
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    const { data } = await axios.post('https://taiaocr.com/api/postfile', formData, {
      onUploadProgress: (e) => {
        if (onUploadCallback) {
          let completionPercentage = Math.round((e.loaded * 100) / e.total);
          // The request does not finish immediately after upload finishes, so we round to 99 here in order to show 99% done in the interface
          if (completionPercentage > 99) {
            completionPercentage = 99;
          }
          onUploadCallback(completionPercentage);
        }
      },
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export const getProjectJobs = async (projectId) => {
  try {
    const { data } = await httpClient.get(`/auth/project/${projectId}/jobs`);
    if (data.success) {
      return data.data;
    } else {
      throw data.data;
    }
  } catch (e) {
    throw e;
  }
};

export const postUpdatePayableWords = async ({ deduct_words, job_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/updatejobwordcount`, {
      deduct_words,
      job_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postProjectPrices = async (project) => {
  const { jobs, is_delivery, id } = project;

  try {
    const { data } = await httpClient.post(`/auth/project/${id}/prices`, {
      delivery_option: is_delivery || 50, // delivery option defaults to 50 when not specified
      jobs,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getProjectPrices = async (project) => {
  const { id } = project;
  try {
    const { data } = await httpClient.get(`/auth/project/${id}/prices`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateDelivery = async (project_id, deliveryId) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${project_id}/prices`, {
      delivery_time_id: deliveryId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getDeliveryDates = async (project) => {
  const { id } = project;

  try {
    const { data } = await httpClient.post(`/auth/projects/${id}/deliveries`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postProjectDiscountCoupon = async ({ discoutCode, projectId }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/discountcoupon`, {
      coupon_code: discoutCode,
      project_id: projectId,
    });

    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.data);
    }
  } catch (e) {
    throw e;
  }
};

export const postProjectPaycheck = async ({
  assigned_user_id,
  calculation = false,
  comment,
  deductBalance = false,
  diy,
  deliveryOption = 50,
  jobs,
  projectId,
}) => {
  try {
    const { data } = await httpClient.post(`/auth/project/paycheck`, {
      assigned_user_id,
      calculation,
      comment,
      deduct_balance: deductBalance,
      diy,
      delivery_option: deliveryOption,
      jobs,
      id: projectId,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.data);
    }
  } catch (e) {
    throw e;
  }
};

export const postOrderProject = async ({ comment, deductBalance, deliveryOption, diy, jobs, projectId }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/orderproject`, {
      comment,
      deduct_balance: deductBalance,
      delivery_option: deliveryOption,
      diy,
      id: projectId,
      jobs,
    });

    if (diy) {
      return data;
    }

    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.data);
    }
  } catch (e) {
    throw e;
  }
};

export const getProjectToken = async ({ projectId }) => {
  try {
    const { data } = await httpClient.get(`/auth/project/${projectId}/token`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getProjectPayment = async (projectId) => {
  try {
    const { data } = await httpClient.get(`/auth/project/${projectId}/payments`);

    return data;
  } catch (e) {
    throw e;
  }
};

export const postProjectPayment = async ({ projectId, deductBalance, nonce, billingAddress, email }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${projectId}/payment`, {
      deduct_balance: deductBalance,
      nonce,
      billingAddress,
      email,
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export const getAdminProjects = async ({
  per_page,
  page,
  project_paid,
  search,
  status_id,
  invoice_issued,
  pm_id,
}) => {
  try {
    const params = {
      per_page,
      page,
      search,
      status_id,
      pm_id,
    };

    // Handle invoice issued
    if (invoice_issued !== null && (invoice_issued === true || invoice_issued === false)) {
      // Convert true => 1, false => 0
      params.invoice_issued = +invoice_issued;
    }

    // Handle paid not paid
    if (project_paid !== null && (project_paid === true || project_paid === false)) {
      // Convert true => 1, false => 0
      params.project_paid = +project_paid;
    }

    const { data } = await httpClient.get(`/auth/admin/projects`, {
      params,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postAdminArchiveProject = async (project_id) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/updatestatus`, {
      id: project_id,
      notification_status: false,
      status_id: PROJECT_STATUS_ARCHIVED,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPmProjects = async (per_page, page) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/gettmpprojects`, {
      params: {
        per_page: per_page,
        page: page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTranslators = async (job_id) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/getlanguagetranslators/${job_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postAssignTranslator = async (job_id, translator_id, deadline) => {
  try {
    const deadlineLj = utcToZonedTime(deadline, 'Europe/Ljubljana');
    const { data } = await httpClient.post(`/auth/admin/assigntranslator`, {
      job_id: job_id,
      translator_id: translator_id,
      translation_end: format(deadlineLj, 'yyyy-MM-dd HH:mm'),
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postAssignRevision = async (job_id, translator_id, deadline) => {
  try {
    const deadlineLj = utcToZonedTime(deadline, 'Europe/Ljubljana');
    const { data } = await httpClient.post(`/auth/admin/assignrevision`, {
      job_id: job_id,
      revisioner: translator_id,
      deadline: format(deadlineLj, 'yyyy-MM-dd HH:mm'),
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postRemoveTranslater = async (job_id) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/removetranslator`, {
      id: job_id,
      job_details: 1,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postRemoveRevisor = async (job_id) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/removetranslator`, {
      id: job_id,
      job_details: 2,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const toggleProjectPaid = async (project_id) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/updatepaid/${project_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateStatus = async (project_id, status_id) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/updatestatus`, {
      id: project_id,
      notification_status: true,
      status_id: status_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postSendInvoice = async (project_id, lang_picked, notification_status) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/sendbill`, {
      projectId: project_id,
      notification_status: notification_status,
      lang_picked: lang_picked,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postResendInvoice = async (project_id, lang_picked) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/resendinvoice`, {
      projectId: '' + project_id,
      lang_picked: lang_picked,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUploadFile = async (project_id, files) => {
  try {
    const formData = new FormData();
    formData.append('project_id', project_id);
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    const { data } = await httpClient.post(`/auth/admin/uploadfile`, formData);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getUpdateZip = async (project_id) => {
  try {
    const { data } = await httpClient.get(`/auth/updatezipfile/${project_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getInvoice = async (projectId) => {
  try {
    const response = await httpClient.get(`auth/project/${projectId}/invoice`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getInvoices = async ({
  project_paid,
  payment_due_from,
  payment_due_to,
  issued_on_from,
  issued_on_to,
  search,
  type,
}) => {
  try {
    const response = await httpClient.get(`auth/admin/invoices-download`, {
      responseType: 'arraybuffer',
      params: { project_paid, payment_due_from, payment_due_to, issued_on_from, issued_on_to, search, type },
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getDownloadInvoice = async (id) => {
  try {
    const response = await httpClient.get(`auth/invoices/${id}/download`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getOriginalFiles = async (projectId) => {
  try {
    const response = await httpClient.get(`auth/project/${projectId}/original-file`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getTranslatedFiles = async (projectId) => {
  try {
    const response = await httpClient.get(`auth/project/${projectId}/translated-file`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getTempProjectFiles = async (projectId) => {
  try {
    const response = await httpClient.get(`auth/tempprojects/${projectId}/file`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const refreshJobTmx = async (jobId) => {
  try {
    const { data } = await httpClient.post(`auth/job/${jobId}/refresh-tmx`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getJobTmx = async (jobId) => {
  try {
    const response = await httpClient.get(`auth/job/${jobId}/tmx`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const postUploadTmxFile = async ({ jobId, file }) => {
  try {
    const formData = new FormData();
    formData.append('tmx', file);
    const { data } = await httpClient.post(`/auth/job/${jobId}/tmx`, formData);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTransferTeam = async ({ projectId, teamId }) => {
  try {
    const { data } = await httpClient.post(`auth/project/${projectId}/team-transfer`, {
      team_id: teamId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getProjectLogs = async ({ page = 1, per_page = 100000, projectId }) => {
  try {
    const { data } = await httpClient.get(`auth/project/${projectId}/logs`, {
      params: {
        page,
        per_page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getAdminInvoices = async ({
  page,
  per_page = 14,
  project_paid,
  issued_on_from,
  issued_on_to,
  payment_due_from,
  payment_due_to,
  search,
  type,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/invoices`, {
      params: {
        page,
        per_page,
        project_paid,
        issued_on_from,
        issued_on_to,
        payment_due_from,
        payment_due_to,
        search,
        type,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postProjectCharge = async ({ project_id, deduct_balance = false, payment_method }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${project_id}/charge`, {
      deduct_balance,
      payment_method,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postConfirmProjectCharge = async ({ project_id, payment_intent }) => {
  try {
    const { data } = await httpClient.post(`/auth/project/${project_id}/charge-confirmation`, {
      payment_intent,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCustomProject = async ({
  name,
  user_id,
  team_id,
  files = [],
  source_language_id,
  jobs = [],
  project_type_id,
  category_id,
  analyze,
  setting_id,
  total_words,
}) => {
  try {
    const formData = new FormData();

    formData.append('name', name);
    if (!!user_id) {
      formData.append('user_id', user_id);
    }

    if (!!team_id) {
      formData.append('team_id', team_id);
    }

    for (let file of files) {
      formData.append('files[]', file);
    }
    formData.append('source_language_id', source_language_id);

    for (let i in jobs) {
      formData.append(`jobs[${i}][target_language_id]`, jobs[i].target_language_id);
      formData.append(`jobs[${i}][payable_words]`, jobs[i].payable_words);
      formData.append(`jobs[${i}][job_url]`, jobs[i].job_url);
      formData.append(`jobs[${i}][job_id]`, jobs[i].job_id);
      formData.append(`jobs[${i}][job_pass]`, jobs[i].job_pass);
      formData.append(`jobs[${i}][translator_payable_words]`, jobs[i].translator_payable_words);
      formData.append(`jobs[${i}][total_words]`, jobs[i].job_total_words);
    }

    formData.append('project_type_id', project_type_id);
    formData.append('category_id', category_id);

    formData.append('analyze', analyze);

    if (!!setting_id) {
      formData.append('setting_id', setting_id);
    }

    formData.append('total_words', total_words);

    const { data } = await httpClient.post(`/auth/projects`, formData);
    return data;
  } catch (e) {
    throw e;
  }
};

export const createProjectJobs = async ({
  project_id,
  target_language_id,
  payable_words,
  job_total_words,
  job_url,
  job_pass,
  job_id,
  translator_payable_words,
}) => {
  try {
    const { data } = await httpClient.post(`/auth/projects/${project_id}/jobs`, {
      target_language_id,
      payable_words,
      job_total_words,
      job_url,
      job_pass,
      job_id,
      translator_payable_words,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const patchProjectJob = async ({
  job_total_words,
  payable_words,
  translator_payable_words,
  job_id,
  job_pass,
  job_url,
  id,
}) => {
  try {
    const { data } = await httpClient.patch(`/auth/jobs/${id}`, {
      job_total_words,
      payable_words,
      translator_payable_words,
      job_pass,
      job_id,
      job_url,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteProjectJob = async ({ id }) => {
  try {
    const { data } = await httpClient.delete(`/auth/jobs/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postAnalysisSuccessful = async ({ id, response }) => {
  try {
    const { data } = await httpClient.post(`/auth/projects/${id}/analysis`, response);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postAnalysisFail = async ({ id, response }) => {
  try {
    const { data } = await httpClient.post(`/auth/projects/${id}/analysis-fail`, response);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatapultJobID = async ({ jobId, referer }) => {
  try {
    const { data } = await httpClient.get(`auth/catapult-job/${jobId}`, {
      params: {
        referer: referer,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const assignPmToProject = async ({ project_id, pm_id }) => {
  try {
    const { data } = await httpClient.patch(`auth/project/${project_id}/project-manager`, {
      pm_id,
    });

    return data;
  } catch (e) {
    throw e;
  }
};
