import axios from 'axios';
import httpClient from './httpclient';

export const getCheckTool = async () => {
  try {
    const { data } = await httpClient.get(`/auth/checktool`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatProject = async ({ baseUrl, catId, catPass }) => {
  try {
    const { data } = await axios.get(`${baseUrl}/api/v2/projects/${catId}/${catPass}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatJobStatus = async ({ baseUrl, catId, catPass }) => {
  try {
    const { data } = await axios.get(`${baseUrl}/api/v2/jobs/${catId}/${catPass}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatProjectStatus = async ({ baseUrl, catId, catPass }) => {
  try {
    const { data } = await axios.get(`${baseUrl}/api/status?id_project=${catId}&project_pass=${catPass}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatProjectCreationStatus = async ({ baseUrl, catId, catPass }) => {
  try {
    const { data } = await axios.get(`${baseUrl}/api/v2/projects/${catId}/${catPass}/creation_status`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postNewMatecatProject = async ({
  baseUrl,
  category,
  files,
  projectName,
  sourceLanguage,
  targetLanguage,
  private_tm_key,
  get_public_matches,
  tms_engine,
}) => {
  try {
    const formData = new FormData();
    formData.append('project_name', projectName);
    formData.append('source_lang', sourceLanguage);
    formData.append('target_lang', targetLanguage);
    formData.append('subject', category);
    formData.append('private_tm_key', private_tm_key);
    formData.append('get_public_matches', get_public_matches);
    formData.append('baseUrl', baseUrl);

    const headers = {};
    if (baseUrl === 'https://catapult.taia.io' || baseUrl === 'https://catapult.taia-translations.com') {
      headers['x-matecat-key'] = process.env.REACT_APP_CAT_CATAPULT_KEY;
      formData.append('mt_engine', 2);

      // disable TM for all catapult users (free/paid) on server "catapult.taia.io"
      if (baseUrl === 'https://catapult.taia.io') {
        formData.append('tms_engine', 0);
      }

      // allow TM only for paid catapult users on server "catapult.taia-translations.com"
      if (baseUrl === 'https://catapult.taia-translations.com') {
        formData.append('tms_engine', tms_engine);
      }
    } else if (baseUrl === 'https://www.matecat.com') {
      headers['x-matecat-key'] = process.env.REACT_APP_CAT_MATECAT_KEY;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    if (baseUrl === 'https://www.matecat.com') {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/createproject/matecat`,
        formData,
        {
          headers: { ...headers, Authorization: 'Bearer ' + localStorage.getItem('authToken') },
        },
      );
      return data;
    }

    const { data } = await axios.post(`${baseUrl}/api/v1/new`, formData, {
      headers: headers,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
