import pdfConverter from 'jspdf';
import 'jspdf-autotable';

import { convertPrice, localiseAndConvertPrice } from '../../../../../utils/string.utils';
import { isJobProofread, resolveSelectedService } from '../../../../../utils/jobs.utils';
import { getCountryLabelTranslation } from '../../../../common/BillingDetails/Billing.utils';
import { formatDate } from 'utils/date.utils';
import { JOB_TITLES } from './translations';

import { currencyByCodeSelector } from '../../../../../store/selectors';
import { getShownServices, hasJobServices } from 'utils/jobs.utils';
import store from '../../../../../store/store';

import isoLogoPng from './assets/iso-logo.png';
import taiaLogoPng from './assets/taia-logo.png';
import { OpenSansBold, OpenSansRegular } from './assets/fonts';
import { parseISO } from 'date-fns';

// eslint-disable-next-line
const addFonts = (doc) => {
  doc.addFileToVFS('OpenSans-Regular.ttf', OpenSansRegular);
  doc.addFont('OpenSans-Regular.ttf', 'OpenSans', 'normal');
  doc.addFileToVFS('OpenSans-Bold.ttf', OpenSansBold);
  doc.addFont('OpenSans-Bold.ttf', 'OpenSans', 'bold');
  doc.setFont('OpenSans');
  return doc;
};

// head logo and taia address
const addHead = ({ doc, t, y }) => {
  // TAIA logo image
  y += 20;

  const logo = new Image();
  logo.src = taiaLogoPng;

  doc.addImage(logo, 'PNG', 140, y, 60, 24.75, undefined, 'FAST');

  // TAIA company name
  doc.setFontSize(11);
  doc.setFont('OpenSans', 'bold');
  doc.text(`${t('common:proforma.head_taia_name')}`, 10, y, {
    maxWidth: 45,
  });
  // TAIA address and VAT info
  y += 11;
  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);
  doc.text(`${t('common:proforma.head_taia_street')}`, 10, y, { maxWidth: 50 });
  y += 5;
  doc.text(`${t('common:proforma.head_taia_city')}`, 10, y, { maxWidth: 50 });
  y += 5;
  doc.text(`${t('common:proforma.head_taia_municipality')}`, 10, y, {
    maxWidth: 50,
  });
  y += 5;
  doc.text(`${t('common:proforma.head_taia_vat_id')}`, 10, y, {
    maxWidth: 50,
  });
  y += 5;
  doc.text(`${t('common:proforma.head_taia_country')}`, 10, y, {
    maxWidth: 50,
  });

  y += 15;

  return { newDoc: doc, newY: y };
};

/**
 * Localise and convert price
 */
const getPrice = ({ currencyData, number, language }) => {
  const localisedPrice = localiseAndConvertPrice({
    currencyData,
    number,
    language,
  });

  return localisedPrice;
};

// customer billing address
// proforma validity
const addCustomerProformaDetails = ({
  billingDetails,
  countries,
  deliveryDate,
  doc,
  i18n,
  projectId,
  t,
  y,
}) => {
  // y is 70 here
  if (billingDetails && billingDetails.company_name) {
    doc.setFont('OpenSans', 'bold');
    doc.text(`${billingDetails.company_name}`, 10, y);
  }

  doc.setFontSize(10);
  doc.text(`${t('common:proforma.head_estimate_number_label')}: `, 165, y, {
    maxWidth: 120,
    align: 'right',
  });

  // in case we have a delivery price the quote is only valid today
  const today = new Date();
  const expirationDate = new Date();

  // Set expiration date 24 hours from now
  expirationDate.setDate(expirationDate.getDate() + 1);

  const expirationString = formatDate({ dateObject: expirationDate, showTime: false, showYear: true });
  const todayString = formatDate({ dateObject: today, showTime: false, showYear: true });

  doc.text(`${projectId}/${todayString.substr(todayString.length - 4)}`, 200, y, {
    maxWidth: 60,
    align: 'right',
  });

  // y is 75 here
  y += 5;
  if (billingDetails && billingDetails.company_street) {
    doc.setFont('OpenSans', 'normal');
    doc.setFontSize(10);

    const countryData = countries.find((country) => country.id === billingDetails.country_id);

    const addressText = countryData
      ? `${billingDetails.company_street}, ${getCountryLabelTranslation(countryData.iso_code, i18n)}`
      : `${billingDetails.company_street}`;

    doc.text(`${addressText}`, 10, y, {
      maxWidth: 60,
    });
  }

  doc.setFontSize(10);
  doc.setFont('OpenSans', 'normal');
  doc.text(`${t('common:proforma.head_estimate_date_label')}: `, 165, y, {
    maxWidth: 60,
    align: 'right',
  });
  doc.text(todayString, 200, y, { maxWidth: 60, align: 'right' });

  y += 5;
  doc.setFont('OpenSans', 'bold');
  doc.text(`${t('common:proforma.head_estimate_validity_label')}: `, 165, y, {
    maxWidth: 60,
    align: 'right',
  });

  doc.text(`${expirationString}`, 200, y, { maxWidth: 60, align: 'right' });

  // Display due date
  if (deliveryDate) {
    const dueDateString = formatDate({ dateObject: parseISO(deliveryDate), showTime: false, showYear: true });

    y += 5;

    doc.setFont('OpenSans', 'normal');

    doc.text(`${t('common:proforma.head_estimate_delivery_date')}: `, 165, y, {
      maxWidth: 60,
      align: 'right',
    });

    doc.text(dueDateString, 200, y, { maxWidth: 60, align: 'right' });
  }

  if (billingDetails.company_vat) {
    y += 10;
    doc.text(`${t('common:proforma.head_vat_id_label')}: ${billingDetails.company_vat}`, 10, y, {
      maxWidth: 60,
    });
  }

  return {
    doc,
    newY: y,
  };
};

// project name and number
const addProjectDetails = ({ doc, projectId, name, t, y }) => {
  y += 10;

  const columns = [
    [
      {
        content: `${t('common:proforma.head_project_number_label')}:`,
        styles: {
          cellWidth: `${t('common:proforma.head_project_number_label')}:`.length * 2.2,
          fontStyle: 'bold',
        },
      },
      `${projectId}`,
    ],
    [
      {
        content: `${t('common:proforma.head_project_name_label')}:`,
        styles: {
          cellWidth: `${t('common:proforma.head_project_name_label')}:`.length * 2.2,
          fontStyle: 'bold',
        },
      },
      `${name}`,
    ],
  ];

  doc.autoTable({
    body: columns,
    didDrawPage: ({ cursor }) => {
      y = cursor.y; // this sets final y of the table after it is drawn
    },
    margin: {
      left: 10,
      top: 10,
      right: 10,
      bottom: 10,
    },
    pageBreak: 'avoid',
    rowPageBreak: 'avoid',
    startY: y,
    styles: {
      cellPadding: { top: 1, left: 0, right: 0, bottom: 0 },
      cellWidth: 'wrap',
      halign: 'left',
      font: 'OpenSans',
      overflow: 'linebreak',
    },
    theme: 'plain',
  });

  return { doc, newY: y };
};

const addProjectJobs = ({ billingDetails, currencyData, doc, i18n, jobs, t, vatRate, y }) => {
  // table head cells string
  // TODO parse these directy with i18n
  const headCells = [
    t('common:proforma.job_description_label'),
    t('common:proforma.job_quantity_label'),
    t('common:proforma.job_unit_label'),
    t('common:proforma.job_ppw_label'),
    t('common:proforma.job_net_total_label'),
    t('common:proforma.job_dpt_label'),
    billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null
      ? t('common:proforma.job_tax_label')
      : null,
    t('common:proforma.job_total_no_tax_label'),
  ];

  const bodyCells = jobs.map((job) => {
    const totalPrice = job.dtp_selected
      ? job.proofread_price
        ? job.proofread_price
        : job[`service_${resolveSelectedService(job)}_price`] + job.dtp_price
      : job.proofread_price
      ? job.proofread_price
      : job[`service_${resolveSelectedService(job)}_price`];

    const netPrice = job.proofread_price
      ? job.proofread_price
      : job[`service_${resolveSelectedService(job)}_price`];

    let jobDescription = '';
    if (isJobProofread(job)) {
      jobDescription = `${t('common:proforma.proofread')}: ${job.source_language.name}`;
    } else {
      const jobTitleTranslationPath = `common:proforma.${JOB_TITLES[resolveSelectedService(job) - 1]}`;

      jobDescription = `${t(jobTitleTranslationPath)}: ${job.source_language.name} - ${
        job.target_language.name
      }`;
    }

    return [
      {
        content: jobDescription,
        styles: { fontStyle: 'bold' },
      },
      `${job.proofread_price ? job.total_words : job.payable_words}`,
      `${t('common:proforma.job_unit')}`,
      {
        content: `${convertPrice({
          currencyData,
          number: job.proofread_price
            ? job.proofread_per_word
            : job[`service_${resolveSelectedService(job)}_ppw`],
        })}`,
        styles: { cellWidth: 'wrap' },
      },
      {
        content: `${getPrice({
          currencyData,
          language: i18n.language,
          number: netPrice,
        })}`,
        styles: { cellWidth: 'wrap' },
      },
      {
        content: `${
          job.dtp_selected
            ? `${getPrice({
                currencyData,
                language: i18n.language,
                number: job.dtp_price,
              })}`
            : 'N/A'
        }`,
        styles: { cellWidth: 'wrap' },
      },
      billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null ? `${vatRate}%` : null,
      {
        content: `${getPrice({
          currencyData,
          language: i18n.language,
          number: totalPrice,
        })}`,
        styles: { cellWidth: 'wrap' },
      },
    ];
  });

  let maxHeaderY = 0;

  doc.autoTable({
    body: bodyCells,
    didDrawCell: ({ cell, column, row, section }) => {
      // -5 here to position line in the middle of the cell bottom padding
      const cellEndY = cell.y + cell.height - 5;

      if (section === 'head') {
        // check which header cell is the highest
        maxHeaderY = cellEndY > maxHeaderY ? cellEndY : maxHeaderY;
        if (column.index === headCells.length - 1) {
          // draw header line here with the last header cell
          doc.line(10, maxHeaderY, 200, maxHeaderY);
        }
      }
      // if last column in body
      if (section === 'body') {
        // check if last row in table
        if (row.index === bodyCells.length - 1) {
          cell.styles.cellPadding = {
            bottom: 10,
            left: 2.5,
            right: 2.5,
            top: 2.5,
          };
        }
      }
    },
    didDrawPage: ({ cursor }) => {
      y = cursor.y; // this sets final y of the table after it is drawn
    },
    head: [headCells],

    headStyles: {
      cellPadding: {
        bottom: 10,
        left: 2.5,
        right: 2.5,
        top: 2.5,
      },
    },
    margin: {
      left: 7.5,
      top: 10,
      right: 7.5,
      bottom: 10,
    },
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    showHead: 'firstPage',
    startY: y + 10,
    styles: {
      cellWidth: 'auto',
      cellPadding: 2.5,
      font: 'OpenSans',
      fontSize: 8,
      overflow: 'linebreak',
    },
    theme: 'plain',
  });

  return { newDoc: doc, newY: y };
};

const addProjectServices = ({ currency, doc, i18n, jobs, projectName, t, vatRate, y }) => {
  const headCells = [
    t('common:proforma.services.description'),
    t('common:proforma.services.units'),
    t('common:proforma.services.amount'),
    t('common:proforma.services.unitCost'),
    t('common:proforma.services.price'),
  ];

  if (!!vatRate) {
    headCells.push(t('common:proforma.services.VAT'));
  }

  const bodyCells = [];

  jobs.forEach((job) => {
    const { job_id, source_language, target_language } = job;
    // first add job row
    bodyCells.push([`job ${job_id}, ${projectName}, ${source_language.code}, ${target_language.code}`]);

    // then map trough selected job service tasks and add tasks rows
    const shownServices = getShownServices(job);
    const shownServicesTasks = shownServices
      .sort((a, b) => b.id - a.id)
      .reduce((accumulator, service) => {
        return [...accumulator, ...service.tasks];
      }, []);

    shownServicesTasks.forEach((task) => {
      const { net_price, quantity, price_per_unit, task_type, unit } = task;

      const localisedNetPrice = getPrice({
        currencyData: currency,
        number: net_price,
        language: i18n.language,
      });

      const pricePerUnit = convertPrice({
        currencyData: currency,
        number: price_per_unit,
      });

      const taskName = t(`services:${task_type.name}`);
      const rowValues = [`      - ${taskName}`, unit.display_name, quantity, pricePerUnit, localisedNetPrice];

      if (!!vatRate) {
        rowValues.push(`${vatRate} %`);
      }

      bodyCells.push(rowValues);
    });
  });

  let maxHeaderY = 0;

  doc.autoTable({
    body: bodyCells,
    didDrawCell: ({ cell, column, row, section }) => {
      // -5 here to position line in the middle of the cell bottom padding
      const cellEndY = cell.y + cell.height - 5;

      if (section === 'head') {
        // check which header cell is the highest
        maxHeaderY = cellEndY > maxHeaderY ? cellEndY : maxHeaderY;
        if (column.index === headCells.length - 1) {
          // draw header line here with the last header cell
          doc.line(10, maxHeaderY, 200, maxHeaderY);
        }
      }
      // if last column in body
      if (section === 'body') {
        // check if last row in table
        if (row.index === bodyCells.length - 1) {
          cell.styles.cellPadding = {
            bottom: 10,
            left: 2.5,
            right: 2.5,
            top: 2.5,
          };
        }
      }
    },
    didDrawPage: ({ cursor }) => {
      y = cursor.y; // this sets final y of the table after it is drawn
    },
    head: [headCells],

    headStyles: {
      cellPadding: {
        bottom: 10,
        left: 2.5,
        right: 2.5,
        top: 2.5,
      },
    },
    margin: {
      left: 7.5,
      top: 10,
      right: 7.5,
      bottom: 10,
    },
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    showHead: 'firstPage',
    startY: y + 10,
    styles: {
      cellWidth: 'auto',
      cellPadding: 2.5,
      font: 'OpenSans',
      fontSize: 8,
      overflow: 'linebreak',
    },
    theme: 'plain',
  });

  return { newDoc: doc, newY: y };
};

const addProjectPrice = ({
  billingDetails,
  currencyData,
  deliveryPrice,
  discountCouponPercentage,
  discountCouponTotal,
  doc,
  grossPrice,
  i18n,
  netPrice,
  regionalDiscountRatio,
  regionalDiscountTotal,
  specialDiscountRatio,
  specialDiscountTotal,
  t,
  vatRate,
  vatTotal,
  y,
}) => {
  // if the page is full add an additional page
  if (y >= 230) {
    doc.addPage();
    y = 12.5;
  }

  y += 4;

  doc.line(10, y, 200, y);
  y += 5;

  const totalDiscount = Math.abs(
    parseFloat(regionalDiscountTotal) + parseFloat(discountCouponTotal) + parseFloat(specialDiscountTotal),
  );

  const columns = [
    !!deliveryPrice && [
      `${t('common:proforma.delivery_time_price_label')}:`,
      `${getPrice({
        currencyData,
        language: i18n.language,
        number: deliveryPrice,
      })}`,
    ],
    !!regionalDiscountRatio
      ? [`${t('common:proforma.ai_discount_label')}:`, `${regionalDiscountRatio} %`]
      : null,
    !!specialDiscountRatio && [
      `${t('common:proforma.special_discount_label')}:`,
      `${specialDiscountRatio} %`,
    ],
    !!discountCouponTotal && [
      `${t('common:projects.projectPrice.discountCoupon')}:`,
      `${discountCouponPercentage} %`,
    ],
    totalDiscount > 0
      ? [
          `${t('common:proforma.discounts_total_label')}:`,
          `-${getPrice({
            currencyData,
            language: i18n.language,
            number: totalDiscount,
          })}`,
        ]
      : null,

    [
      {
        content: `${t('common:proforma.total_price_label')}:`,
        styles: {
          fontStyle:
            billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null ? 'normal' : 'bold',
        },
      },
      {
        content: `${getPrice({
          currencyData,
          language: i18n.language,
          number: netPrice,
        })}`,
        styles: {
          fontStyle:
            billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null ? 'normal' : 'bold',
        },
      },
    ],
    (billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null) && [
      `${t('common:proforma.tax_total_label')} ${vatRate === null ? 0 : vatRate}% ${t(
        'common:proforma.tax_base_label',
      )} ${getPrice({
        currencyData,
        language: i18n.language,
        number: netPrice,
      })}:`,
      `${getPrice({
        currencyData,
        language: i18n.language,
        number: vatTotal,
      })}`,
    ],
    (billingDetails.vat_rate !== null || billingDetails.digital_vat_rate !== null) && [
      {
        content: `${t('common:proforma.total_with_tax_label')}:`,
        styles: { fontStyle: 'bold' },
      },
      {
        content: `${getPrice({
          currencyData,
          language: i18n.language,
          number: grossPrice,
        })}`,
        styles: { fontStyle: 'bold' },
      },
    ],
  ].filter((row) => row);

  doc.autoTable({
    body: columns,
    didDrawPage: ({ cursor }) => {
      y = cursor.y; // this sets final y of the table after it is drawn
    },
    margin: {
      left: 90,
      top: 10,
      right: 10,
      bottom: 10,
    },
    pageBreak: 'avoid',
    rowPageBreak: 'avoid',
    startY: y,
    styles: {
      cellPadding: 1,
      cellWidth: 'wrap',
      font: 'OpenSans',
      halign: 'right',
      overflow: 'linebreak',
    },
    theme: 'plain',
  });

  return { newDoc: doc, newy: y };
};

const addCurrenciesText = ({ currencyData, doc, grossPrice, i18n, netPrice, t, y }) => {
  if (y >= 215) {
    doc.addPage();
    y = 20;
  } else {
    y += 10;
  }

  const state = store.getState();
  const getCurrencyByCode = currencyByCodeSelector({ ...state });

  const EURCurrency = getCurrencyByCode('EUR');
  const GBPCurrency = getCurrencyByCode('GBP');

  const GBPConversionRate = parseFloat(GBPCurrency.conversion_rate).toFixed(2);
  const currencyConversionRate = parseFloat(currencyData.conversion_rate).toFixed(2);

  const totalPrice = grossPrice !== null ? grossPrice.toFixed(2) : netPrice.toFixed(2);

  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);

  if (currencyData.code === 'GBP') {
    // if invoice is issued in GBP add current conversion rate to EUR and final price in EUR
    const EURtext = `${t('common:proforma.conversions.totalPrice')} EUR: ${getPrice({
      currencyData: EURCurrency,
      language: i18n.language,
      number: totalPrice,
    })}. ${t('common:proforma.conversions.conversionRate')} ${(1 / GBPConversionRate).toFixed(2)}. ${t(
      'common:proforma.conversions.disclaimer',
    )}.`;
    doc.text(EURtext, 10, y, {
      maxWidth: 180,
    });
    y += 5;
    return {
      doc,
      y,
    };
  } else if (currencyData.code === 'EUR') {
    // if invoice is issued in EUR add current active conversion rate to GBP and final price in GBP
    const GBPText = `${t('common:proforma.conversions.totalPrice')} GBP: ${getPrice({
      currencyData: GBPCurrency,
      language: i18n.language,
      number: totalPrice,
    })}. ${t('common:proforma.conversions.conversionRate')} ${GBPConversionRate}.`;
    doc.text(GBPText, 10, y, {
      maxWidth: 180,
    });
    y += 5;
    return {
      doc,
      y,
    };
  } else {
    // show both EUR and GBP
    const currencyText = `${t('common:proforma.conversions.totalPrice')} EUR: ${getPrice({
      currencyData: EURCurrency,
      language: i18n.language,
      number: totalPrice,
    })}. ${t('common:proforma.conversions.conversionRate')} ${currencyConversionRate}. ${t(
      'common:proforma.conversions.totalPrice',
    )} GBP: ${getPrice({
      currencyData: GBPCurrency,
      language: i18n.language,
      number: totalPrice,
    })}. ${t('common:proforma.conversions.conversionRate')} ${(
      GBPConversionRate / currencyConversionRate
    ).toFixed(2)}. ${t('common:proforma.conversions.disclaimer')}.`;
    doc.text(currencyText, 10, y, {
      maxWidth: 180,
    });
    y += 10;
    return {
      doc,
      y,
    };
  }
};

const addPaymentInfo = ({ currencyData, doc, y, t }) => {
  if (y >= 240) {
    doc.addPage();
    y = 20;
  } else {
    y += 5;
  }

  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);

  doc.text(t('common:proforma.paymentInfo.heading'), 10, y, {
    maxWidth: 180,
  });
  y += 5;

  // add payment info in the currency that the bill is in
  // default to GBP for currencies that do not have a payment account (SGD at the moment)

  doc.text(t(`common:proforma.paymentInfo.${currencyData.code}`, 'common:proforma.paymentInfo.GBP'), 10, y, {
    maxWidth: 180,
  });
  y += 5;

  return {
    doc,
    y,
  };
};

const addFooter = ({ doc, y, t }) => {
  // if the page is full add an additional page
  if (y >= 215) {
    doc.addPage();
    y = 20;
  } else {
    y += 10;
  }

  const isoImage = new Image();
  isoImage.src = isoLogoPng;

  doc.addImage(isoImage, 'PNG', 100, y - 5, 100, 47, undefined, 'FAST');

  doc.setFont('OpenSans', 'normal');
  doc.setFontSize(10);
  y += 12.5;
  doc.text(
    `${t('common:proforma.footer_company_info')} | ${t('common:proforma.footer_contant_info')}`,
    10,
    y,
    {
      maxWidth: 90,
    },
  );

  return doc;
};

export const generateProformaPdf = ({
  billingDetails,
  countries,
  currencyData,
  deliveryOption,
  deliveryPrice,
  deliveryDate,
  discountCouponPercentage,
  discountCouponTotal,
  grossPrice,
  i18n,
  jobs,
  netPrice,
  projectId,
  projectName,
  regionalDiscountRatio,
  regionalDiscountTotal,
  specialDiscountRatio,
  specialDiscountTotal,
  t,
  userRole,
  vatRate,
  vatTotal,
}) => {
  let doc = new pdfConverter({ filters: ['ASCIIHexEncode'] });
  doc.setLanguage(i18n.language);
  doc = addFonts(doc);

  let y = 0;

  const addHeadResult = addHead({
    doc,
    t,
    y,
  });
  doc = addHeadResult.newDoc;
  y = addHeadResult.newY;

  const proformaDetailsResult = addCustomerProformaDetails({
    billingDetails,
    countries,
    deliveryPrice,
    deliveryDate,
    doc,
    i18n,
    projectId,
    t,
    y,
  });
  doc = proformaDetailsResult.doc;
  y = proformaDetailsResult.newY;

  const projectDetailsResult = addProjectDetails({
    doc,
    projectId,
    name: projectName,
    t,
    y,
  });
  doc = projectDetailsResult.doc;
  y = projectDetailsResult.newY;

  try {
    const jobsResult = hasJobServices(jobs)
      ? addProjectServices({
          currency: currencyData,
          doc,
          i18n,
          jobs,
          projectName,
          t,
          vatRate,
          y,
        })
      : addProjectJobs({
          billingDetails,
          currencyData,
          doc,
          i18n,
          jobs,
          t,
          vatRate,
          y,
        });

    doc = jobsResult.newDoc;
    y = jobsResult.newY;
  } catch (e) {
    console.error(e);
  }

  const priceResult = addProjectPrice({
    billingDetails,
    currencyData,
    deliveryOption,
    deliveryPrice,
    discountCouponPercentage,
    discountCouponTotal,
    doc,
    grossPrice,
    i18n,
    netPrice,
    regionalDiscountRatio,
    regionalDiscountTotal,
    specialDiscountRatio,
    specialDiscountTotal,
    t,
    vatRate,
    vatTotal,
    y,
  });
  doc = priceResult.newDoc;
  y = priceResult.newy;

  const addCurrenciesResult = addCurrenciesText({
    currencyData,
    doc,
    grossPrice,
    i18n,
    netPrice,
    t,
    y,
  });

  doc = addCurrenciesResult.doc;
  y = addCurrenciesResult.y;

  const addPaymentInfoResult = addPaymentInfo({ currencyData, doc, y, t });

  doc = addPaymentInfoResult.doc;
  y = addPaymentInfoResult.y;

  doc = addFooter({ doc, y, t, userRole });

  doc.save(`${projectName}-Quote.pdf`);
};
