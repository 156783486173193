import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CatapultProjectOrdered from './CatapultProjectOrdered/CatapultProjectOrdered';
import OrderProject from './OrderProject/OrderProject';
import ProjectInReview from './ProjectInReview/ProjectInReview';
import UpdateWrapper from '../Projects/ProjectsCards/UpdateWrapper';
import ProjectOrdered from './ProjectOrdered/ProjectOrdered';
import { MainContainer } from 'components/common.styles';

import Message from '../common/Message/Message';
import LoadingPage from 'components/common/LoadingPane/LoadingPane';

import { getCatProject } from '../../services/cat';
import { getProject, getProjectJobs, getProjectLogs, getProjectPrices } from '../../services/project';
import { getBillingDetails } from '../../services/billing';

import { updateProject } from '../../store/projectsSlice';
import ProjectLoader from './ProjectLoader/ProjectLoader';
import ProjectWaiting from './ProjectWaiting/ProjectWaiting';
import { withTranslation } from 'react-i18next';
import { PROJECT_STATUS_FAILED } from 'constants/projectStatus';
import ProjectFailed from './ProjectFailed/ProjectFailed';
class Project extends Component {
  state = {
    error: null,
    isLoading: true, // component loads data on mount
    pricesData: null,
    projectBillingDetails: null,
  };

  findProject = () => {
    const { match, projects } = this.props;
    const id = parseInt(match.params.id);
    const project = projects.find((project) => project.id === id);
    return project;
  };

  componentDidMount = async () => {
    this.fetchProject();
  };

  fetchProject = async () => {
    const { dispatch, match, t } = this.props;
    const id = parseInt(match.params.id);

    this.setState({
      isLoading: true,
    });

    try {
      const projectResponse = await getProject(id);

      if (projectResponse.success) {
        const billingDetails = await this.getBillingDetails(projectResponse.data);
        let projectJobs = await getProjectJobs(id);
        const projectLogs = await getProjectLogs({ projectId: id });

        // Get project progress
        const progress = await this.getProjectProgress(projectResponse.data);

        const { cat_id, cat_pass, cat_setting } = projectResponse.data;
        const status_id = projectResponse.data.status_id;
        //Match MateCat jobs with project Jobs
        projectJobs = projectJobs.map((job) => {
          let jobProgress = 0;
          if (status_id === 0 || status_id === 1 || status_id === 2) {
            jobProgress = 0;
          } else if (status_id === 6 || status_id === 20) {
            jobProgress = 100;
          } else if (status_id === 5 || status_id === 51 || status_id === 52 || status_id === 53) {
            jobProgress = 90;
          } else {
            if (cat_id && cat_pass && cat_setting) {
              if (progress && progress.jobs && progress.jobs.length > 0) {
                jobProgress = progress.jobs.find((progJob) => progJob.id === job.job_id).stats.PROGRESS_PERC;
              }
            }
          }
          job.progress = jobProgress;
          return job;
        });

        const newProject = {
          ...projectResponse.data,
          jobs: projectJobs,
          logs: projectLogs.data,
          progress: progress.progress,
        };

        const pricesData = await getProjectPrices(newProject);

        dispatch(updateProject({ project: newProject }));

        this.setState({
          error: null,
          isLoading: false,
          pricesData,
          projectBillingDetails: billingDetails,
        });
      } else {
        this.setState({
          error: t('common:projects.projectInfo.notFound'),
          isLoading: false,
        });
      }
    } catch (e) {
      const error = window.navigator.onLine
        ? t('common:projects.projectInfo.notFound')
        : t('common:errors.noConnection');
      this.setState({
        error,
        isLoading: false,
      });
      throw e;
    }
  };

  getProjectProgress = async ({ cat_id, cat_pass, cat_setting, status_id }) => {
    try {
      const catStatuses = [3, 4, 10, 11];
      const jobs = [];
      let progress = 0;
      if (status_id === 0 || status_id === 1 || status_id === 2) {
        progress = 0;
      } else if (status_id === 6 || status_id === 20) {
        progress = 100;
      } else if (status_id === 5 || status_id === 51 || status_id === 52 || status_id === 53) {
        progress = 90;
      } else if (catStatuses.includes(status_id)) {
        if (cat_id && cat_pass && cat_setting) {
          const getCatProjectResponse = await getCatProject({
            baseUrl: cat_setting.link,
            catId: cat_id,
            catPass: cat_pass,
          });

          const arr = [];
          getCatProjectResponse.project.jobs.forEach((job) => {
            arr.push(job.stats.PROGRESS_PERC);
            jobs.push(job);
          });
          const reducedProgress = (arr.reduce((a, b) => a + b, 0) / arr.length) * 0.9;

          if (reducedProgress > 90) {
            progress = 90;
          }
        } else {
          progress = 0;
        }
      }
      return {
        progress: progress,
        jobs: jobs,
      };
    } catch (e) {
      const project = this.findProject();

      return {
        jobs: project ? project.jobs : [],
        progress: null,
      };
    }
  };

  // this should only be called after we successfully retrieved the project data
  getBillingDetails = async (projectData) => {
    try {
      if (projectData.billing_detail_id) {
        const billingResponse = await getBillingDetails(projectData.billing_detail_id);
        return billingResponse;
      }
      return null;
    } catch (e) {
      throw e;
    }
  };

  setPricesData = (pricesData) => {
    this.setState({ pricesData });
  };

  showInReviewMessage = (project) => {
    const { user } = this.props;

    if (project.status_id === 21 && user.role === 42) {
      const projectTeamId = project.team.id;
      const userTeam = user.teams.find((team) => team.id === projectTeamId);
      return !!userTeam && userTeam.pivot.role_id !== 1;
    }
  };

  render() {
    const { projectBillingDetails, error, isLoading, pricesData } = this.state;

    const project = this.findProject();

    return (
      <>
        {isLoading ? (
          <MainContainer>
            <LoadingPage height={'100%'} />
          </MainContainer>
        ) : error || !project ? (
          <MainContainer>
            <Message text={error} type="error" />
          </MainContainer>
        ) : project.status_id === 1 ? (
          <MainContainer>
            <UpdateWrapper project={project} reloadProjects={() => this.fetchProject()}>
              <ProjectLoader setStepParam />
            </UpdateWrapper>
          </MainContainer>
        ) : project.status_id === 22 ? (
          <MainContainer>
            <ProjectWaiting project={project} refreshProject={() => this.fetchProject()} />
          </MainContainer>
        ) : project.status_id === PROJECT_STATUS_FAILED ? (
          <MainContainer className="main-container-project--status-failed">
            <ProjectFailed project={project} reloadProjects={() => this.fetchProject()} />
          </MainContainer>
        ) : this.showInReviewMessage(project) ? (
          <MainContainer>
            <ProjectInReview />
          </MainContainer>
        ) : project.status_id === 2 || project.status_id === 21 ? (
          <OrderProject
            billingDetails={projectBillingDetails}
            project={project}
            pricesData={pricesData}
            setPricesData={this.setPricesData}
            refreshProject={() => this.fetchProject()}
          />
        ) : project.diy ? (
          <MainContainer>
            <CatapultProjectOrdered
              project={project}
              pricesData={pricesData}
              refreshProject={() => this.fetchProject()}
            />
          </MainContainer>
        ) : (
          <ProjectOrdered
            billingDetails={projectBillingDetails}
            project={project}
            pricesData={pricesData}
            onSubmit={() => this.fetchProject()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  billingDetails: state.userStore.billingDetails,
  projects: state.projectsStore.projects,
  user: state.userStore.user,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Project)));
