import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import React from 'react';
import CombinationsTable from './CombinationsTable/CombinationsTable';

const VendorLanguageCombinations = ({ vendorData, onCombinationsChange }) => {
  const handleCombinationsChange = (newPrices) => {
    const newVendorData = {
      ...vendorData,
      price: newPrices,
    };
    // Set vendor data
    onCombinationsChange(newVendorData);
  };
  return (
    <TabContainer>
      <div className="vendor-language-combinations">
        <CombinationsTable
          initialCombinations={vendorData.prices}
          vendorId={vendorData.id}
          onChange={handleCombinationsChange}
        />
      </div>
    </TabContainer>
  );
};

export default VendorLanguageCombinations;
